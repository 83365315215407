body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a{
  text-decoration: none;
}

html, body, #root {
  height: 100%;
  background-color:white;
}

#root {
  display: flex;
  flex-direction: column;
}

footer {
  margin-top: auto;
}

.identicon {
  border-radius: 50%;
}